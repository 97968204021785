import { createApp } from 'vue'
import App from './App.vue'
import Store from "./Store"
import I18n from './I18n';

import {version} from '../package.json';
console.log(`Aliaxis Showroom - Version ${version} Starting...`);
console.log("");

// global method to get media files
const getMedia = ((url) => {
  return new URL(`/src/assets/${url}`, import.meta.url).href;
});

// Create App
let app = createApp(App);
app.config.globalProperties.$getMedia = getMedia;
app.use(Store);
app.use(I18n);
app.mount('#app');