import { createI18n } from 'vue-i18n'

let i18n = createI18n({
	locale: 'en', // default locale will be overwritten by the products.json
	fallbackLocale: 'en',
	messages: {},
	silentFallbackWarn: true,
	silentTranslationWarn: true
});

export default i18n;