<template>
	<div>
		<Pin :id="1" :position="v_1" :alpha="3.49" :beta="1.17" :target="t_1"/>
		<Pin :id="2" :position="v_2" :alpha="4.89" :beta="0.95"/>
		<Pin :id="3" :position="v_3" :alpha="Math.PI/2" :beta="Math.PI/2-0.3"/>
		<Pin :id="4" :position="v_4" :alpha="Math.PI" :beta="Math.PI/2-0.3"/>
		<Pin :id="5" :position="v_5" :alpha="4.47" :beta="1.12"/>
		<Pin :id="6" :position="v_6" :alpha="3.14" :beta="1.37"/>
		<Pin :id="7" :position="v_7" :alpha="4.65" :beta="1.05"/>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Pin from "@/components/babylonjs/Pin.vue";

import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";
import { Vector3, Color3 } from "@babylonjs/core/Maths/math";
import { Animation } from "@babylonjs/core/Animations/animation";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Texture } from "@babylonjs/core/Materials/Textures/texture"
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from '@babylonjs/core/Actions/directActions';
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import "@babylonjs/loaders/glTF"; // GLTF Loader

export default {
	components:{Pin},
	data(){
		this.v_1 = new Vector3(2, 1.3, 0);
		this.t_1 = new Vector3(-2,1,-1),
		this.v_2 = new Vector3(2, -1, -1);
		this.v_3 = new Vector3(2.4, -0.9, 3.25);
		this.v_4 = new Vector3(-2, 0.5, -1);
		this.v_5 = new Vector3(3, -0.7, -7.5);
		this.v_6 = new Vector3(-1, 0.4, -5);
		this.v_7 = new Vector3(3, 0.4, -9);

		this.ease = null;
		this.ground = null;
		this.lightmaps = [];

		// Tooltip
		this.isDragging = false;
		this.tootltipNode = null;
		this.mouseX = 0;
		this.mouseY = 0;

        return {}
    },
    computed: {
		...mapGetters([
			'isGroundOpen','isWaterEnabled','isSewageEnabled','isGasEnabled','products',
			'isInfoPanelOpen','activeLanguage','availableSegments',"isTooltipOpen","toolTipsDisabled"
		]),
	},
	watch:{
		isGroundOpen(){
			window.scene.getNodeByName('Targets').setEnabled(this.isGroundOpen);
			Animation.CreateAndStartAnimation("",this.ground, "position.y", 60, 80, this.ground.position.y, this.isGroundOpen ? -2.2 : 0, 0, this.ease);
			Animation.CreateAndStartAnimation("",this.ground, "visibility", 60, 80, this.ground.visibility, this.isGroundOpen ? 0 : 1, 0, this.ease);
		},
		isWaterEnabled(){
			this.enableCategory("Wasser",this.isWaterEnabled ? 1 : 0);
			this.adjustLightMaps();
		},
		isSewageEnabled(){
			this.enableCategory("Abwasser",this.isSewageEnabled ? 1 : 0);
			this.adjustLightMaps();
		},
		isGasEnabled(){
			this.enableCategory("Gas",this.isGasEnabled ? 1 : 0);
			this.adjustLightMaps();
		},
	},
    methods:{
		...mapMutations([
			'setInfoPanelOpen','setInfoPanelData','setActiveLanguage',
			"setWaterBool","setSewageBool","setGasBool", "setTooltipOpen", "setTooltipData"
		]),
		initialize(){
			new TransformNode("Pins"); // Create TransformNode for Pins

			this.initEventlisteners();
			this.loadAssets();
		},	
        async loadAssets(){
			// Main Island
			let islandTask = window.assetsManager.addMeshTask("","","",this.$getMedia("meshes/ALX_20230927_AliaxisWorld_V38-abnahme.glb"));
			islandTask.onSuccess = ()=>{ 
				this.ground = window.scene.getMeshByName("GrabenDeckel");

				this.cloneMaterials();
				this.addHoverEffect();

				// Not all segments are available in all language. If not aviable deactivate them (change lightmap, remove meshes, etc.)
				this.setWaterBool(this.availableSegments.water);
				this.setGasBool(this.availableSegments.gas);
				this.setSewageBool(this.availableSegments.sewage);
			}

			// Skybox
			let skyboxTask = window.assetsManager.addCubeTextureTask("",this.$getMedia("textures/aliaxis_env_3.env"));
			skyboxTask.onSuccess = (task) => { 
				window.scene.environmentTexture = task.texture;
				window.scene.environmentIntensity = 1.8;
			}

			// Lightmaps
			this.lightmaps = new Map();
			let lightmapNames = [
				"graben_wasser_abwasser",
				"graben_gas_abwasser",
				"graben_wasser_gas",
				"graben_wasser",
				"graben_abwasser",
				"graben_gas",
				"graben_ohne",
				"haus_1_ohne",
				"haus_3_ohne"
			];
			lightmapNames.forEach(lightmapName => {
				let lightMapTask = window.assetsManager.addTextureTask("", this.$getMedia("textures/lightmaps/" + lightmapName + ".jpg"), false, false, Texture.LINEAR_LINEAR_MIPLINEAR);
				lightMapTask.onSuccess = (task) => { 
					let lightmap = task.texture;
					this.lightmaps.set(lightmapName, lightmap);
				}	
			})

			// Add Clouds/Birds
			let environmentNode = new TransformNode("Environment");
			this.createTexturePlane(new Vector3(8,5,-10),"clouds1.png",9,environmentNode);
			this.createTexturePlane(new Vector3(-5,8,15),"clouds3.png",6,environmentNode);
			this.createTexturePlane(new Vector3(-10,6,-15),"clouds2.png",10,environmentNode);	
			
			// Load assets
			window.assetsManager.load();
        },
		adjustLightMaps(){
			// Lightmap Ground
			let lightmap_ground;
			if(this.isWaterEnabled && this.isSewageEnabled && !this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_wasser_abwasser');
			}
			else if(!this.isWaterEnabled && this.isSewageEnabled && this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_gas_abwasser');
			}
			else if(this.isWaterEnabled && !this.isSewageEnabled && this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_wasser_gas');
			}
			else if(this.isWaterEnabled && !this.isSewageEnabled && !this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_wasser');
			}
			else if(!this.isWaterEnabled && this.isSewageEnabled && !this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_abwasser');
			}
			else if(!this.isWaterEnabled && !this.isSewageEnabled && this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_gas');
			}
			else if(!this.isWaterEnabled && !this.isSewageEnabled && !this.isGasEnabled){
				lightmap_ground = this.lightmaps.get('graben_ohne');
			}
			else if(this.isWaterEnabled && this.isSewageEnabled && this.isGasEnabled){
				lightmap_ground = window.scene.getTextureByName("Graben (Base Color)");
			}
			window.scene.getMaterialByName("Graben").albedoTexture = lightmap_ground;
			
			// Lightmap House
			let lightmap_house1;
			let lightmap_house3;
			if(!this.isSewageEnabled){
				lightmap_house1 = this.lightmaps.get('haus_1_ohne');
				lightmap_house3 = this.lightmaps.get("haus_3_ohne");
			}else{
				lightmap_house1 = window.scene.getTextureByName("house_1 (Base Color)");
				lightmap_house3 = window.scene.getTextureByName("Haus_3 (Base Color)")
			}
			window.scene.getMaterialByName("house_1").albedoTexture = lightmap_house1;
			window.scene.getMaterialByName("Haus_3").albedoTexture = lightmap_house3;
		},
		enableCategory(name,visibility){
			window.scene.getNodeByName(name).setEnabled(visibility != 0);

			let char = (name == "Abwasser" ? "a" : name == "Wasser" ? "w" : "g");
			window.scene.getNodeByName("Targets").getChildMeshes().forEach(child => {
				if(child.name.charAt(0) == char)
					child.setEnabled(visibility != 0)
			});
        },
		createTexturePlane(position,url,size,environmentNode){
			let plane = MeshBuilder.CreatePlane(url, {size: size}, window.scene);
			plane.position = position;
			plane.parent = environmentNode;
			plane.billboardMode = 7; // (7 = Mesh.BILLBOARDMODE_ALL)
			plane.material = new StandardMaterial("TexturePlaneMaterial", window.scene);
			plane.material.opacityTexture = plane.material.diffuseTexture = new Texture(this.$getMedia("textures/" + url), window.scene);
			plane.material.emissiveColor = new Color3(255,255,255);
			plane.material.disableLighting = true;
			plane.visibility = 0.8;
			plane.isPickable = false;
		},
		cloneMaterials(){
			// Clone Materials for each mesh to stop sharing materials. We can then animate each material (e.g. on hover over)
			let meshes = [];
			meshes = meshes.concat(window.scene.getNodeByName("Wasser").getDescendants(false));
			meshes = meshes.concat(window.scene.getNodeByName("Gas").getDescendants(false));
			meshes = meshes.concat(window.scene.getNodeByName("Abwasser").getDescendants(false));
			meshes = meshes.concat(window.scene.getNodeByName("Friamat").getDescendants(false));
			meshes = meshes.concat(window.scene.getNodeByName("iPhone").getDescendants(false));

			meshes.forEach(mesh => {
				if(mesh.material && mesh.getClassName() == "Mesh")
					mesh.material = mesh.material.clone(mesh.material.name + "_cloneForAnimation");
			});
		},
		addHoverEffect(){
			let assignments = [ // This array links the productIDs from the products.json from the aliaxis server to meshes/nodes in the 3D Modell
				// Sewage Segment
				{mesh: "A1", productID: 1000},
				{mesh: "A2", productID: 1001},
				{mesh: "A3_Grau", productID: 1002},
				{mesh: "A4", productID: 1003},
				// {mesh: "A5", productID: 1004},
				{mesh: "A6", productID: 1005},
				{mesh: "A7", productID: 1006},
				{mesh: "A8", productID: 1007},
				{mesh: "4", productID: 1008}, 
				{mesh: "EasyclipCity", productID: 1009},
				{mesh: "Bend45ss", productID: 1010},
				{mesh: "Branch45DS", productID: 1011},
				{mesh: "AccessPlug", productID: 1012},
				{mesh: "NonReturnValveClassica", productID: 1013},
				{mesh: "AccessJunctionBasis", productID: 1014},
				{mesh: "TrapInterceptor", productID: 1015},
				{mesh: "InvertReducer", productID: 1016},
				{mesh: "A3_Orange", productID: 1017},
				{mesh: "NonReturnValve", productID: 1018},
				{mesh: "PPTrappedRainwaterGulli", productID: 1019},
				{mesh: "Ueberschiebmuffe", productID: 1020},

				// Water Segment
				{mesh: "W1", productID: 1100},
				{mesh: "W2", productID: 1101},
				{mesh: "W3", productID: 1102},
				{mesh: "W4", productID: 1103},
				{mesh: "W5", productID: 1104},
				{mesh: "W6", productID: 1105},
				{mesh: "W7", productID: 1106},
				{mesh: "W8", productID: 1107},
				{mesh: "W9", productID: 1108},
				{mesh: "W10", productID: 1109},
				{mesh: "W11", productID: 1110}, 
				{mesh: "FriaClamp_1", productID: 1111},
				{mesh: "FriaClamp_2", productID: 1112},

				// Gas Segment
				{mesh: "G1", productID: 1200},
				{mesh: "G2", productID: 1201},
				{mesh: "G3", productID: 1202},
				{mesh: "G4", productID: 1203},
				{mesh: "G5", productID: 1204},
				{mesh: "G6", productID: 1205},
				{mesh: "G7", productID: 1206},
				{mesh: "G8", productID: 1207},
				{mesh: "G9", productID: 1208},
				{mesh: "G10", productID: 1209},
				{mesh: "G11", productID: 1210},
				{mesh: "G12", productID: 1211},
				{mesh: "G13", productID: 1212},
				{mesh: "G14", productID: 1213},
				{mesh: "W7_Gas", productID: 1214},

				// Extras
				{mesh: "Friamat", productID: 1300},
				{mesh: "iPhone", productID: 1301},
			]

			console.log("");

			// In the product.json find the product with the corresponding ID and add hover over/click effect to the 3D Modell of the product
			this.products.forEach(product => {
				let assignment = assignments.find(a => a.productID == product.DO_NOT_CHANGE_ID);
				if(!assignment){
					console.log("Product: " + product.data[0].title + " is missing an assignment, but is in products.json");
					console.log("");
					return;
				}

				let node = window.scene.getNodeByName(assignment.mesh);
				if(!node){
					console.log("Assignet Mesh to product: " + product.data[0].title + " (Mesh name: " + assignment.mesh + ") not found in AliaxisWorld.glb");
					console.log("");
					return;
				}

				if(node.getClassName() == "Mesh")
					this.addPointerEventsToMeshes(node.name, [node], assignment.productID);
				else if(node.getClassName() == "TransformNode")
					this.addPointerEventsToMeshes(node.name, node.getChildMeshes(), assignment.productID);
			});

			// Hide all Triggerboxes incase triggberbox has no assignment
			window.scene.getNodeByName("Targets").getChildMeshes().forEach(mesh => { 
				if(mesh.visibility != 0){
					console.log("TriggerBox: " + mesh.name + " exsists but has no assignment")
					console.log("");
					mesh.visibility = 0;
				}
			});
		},
		addPointerEventsToMeshes(nodeName, meshes, productID){
			let triggerBox = this.getTriggerbox(nodeName); // get Collider for product
			if(!triggerBox){
				console.log(nodeName + " is missing a triggerbox");
				console.log("");
				return;
			}
			triggerBox.visibility = 0.0;

			// OnHoverEnter: Highlight Product and show Tooltip
			triggerBox.actionManager = new ActionManager(window.scene);
			triggerBox.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
				if(this.isGroundOpen && !this.toolTipsDisabled && !this.isDragging){
					meshes.forEach(mesh => {
						Animation.CreateAndStartAnimation("",mesh, "material.alpha", 60, 20, mesh.material.alpha, 0.5, 0, this.ease);
					});

					// Open Tooltip
					let tooltipData = {
						top: (this.mouseY - document.getElementById("tooltip").offsetHeight-35) + "px",
						left: (this.mouseX - (document.getElementById("tooltip").offsetWidth/2)) + "px",
						productID:  productID
					}
					this.setTooltipData(tooltipData);
					this.setTooltipOpen(true);
				}
			}));

			// OnHoverLeave: Unhighlight Product and hide Tooltip
			triggerBox.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
				if(this.isGroundOpen && !this.toolTipsDisabled && !this.isDragging){
					meshes.forEach(mesh => {
						Animation.CreateAndStartAnimation("",mesh, "material.alpha", 60, 30, mesh.material.alpha, 1, 0, this.ease);
					});
					this.setTooltipOpen(false);
				}
			}));

			// OnClick: Open InfoPanel
			triggerBox.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger,()=>{
				this.setInfoPanelData(this.products.find(product => product.DO_NOT_CHANGE_ID == productID));		
				this.setInfoPanelOpen(true);
			}));
		},
		getTriggerbox(nodeName){
			let triggerBoxName = nodeName.toLowerCase() + "_target";
			return window.scene.meshes.find(mesh => mesh.name.toLowerCase() == triggerBoxName);
		},

		// 
		// Add Eventlisteners for Interaction (zoom, mouse move etc.)
		// 
		initEventlisteners(){
			// Save Mouse/Pointer Position for Tooltip 
			document.body.addEventListener("mousemove", (event)=>{
				this.mouseX = event.clientX;
				this.mouseY = event.clientY;
			});

			// On Click on canvas close infopanel when opened. Deactivate Tooltips so user wont open them when navigating arround
			document.getElementById("renderCanvas").addEventListener("pointerdown", ()=>{
				this.isDragging = true;

				if(this.isInfoPanelOpen)
					this.setInfoPanelOpen(false);

				if(this.isTooltipOpen)
					this.setTooltipOpen(false);
			});

			// Activate Tooltips again when user stopped navigating
			document.getElementById("renderCanvas").addEventListener("pointerup", ()=>{
				this.isDragging = false;
			});
			
			// Zoom function
			document.getElementById("renderCanvas").addEventListener('wheel', (e) => {
				let camera = window.scene.activeCamera;
				let scale = e.deltaY ? e.deltaY: e.scale;

				let i = 0;
				let intervalID = setInterval(() => {
					if(camera.fov < 1.1 && 0 < scale)
						camera.fov += 0.005;
					if(0.75 < camera.fov && scale < 0)
						camera.fov -= 0.005;

					i++;
					if(i == 15){
						i = 0;
						clearInterval(intervalID);
					}
				}, 1);
			});

			// Touch Dislay
			document.addEventListener('touchstart', (event)=>{
				if(this.tootltipNode){ // Unhighlight old tooltipNode					
					if(this.tootltipNode.material)
						Animation.CreateAndStartAnimation("",this.tootltipNode,"material.emissiveColor",24,6,this.tootltipNode.material.emissiveColor,new Color3(0,0,0),0,this.ease);
					this.tootltipNode.getChildMeshes().forEach(child => {
						Animation.CreateAndStartAnimation("",child,"material.emissiveColor",24,6,child.material.emissiveColor,new Color3(0,0,0),0,this.ease);
					});
					this.setTooltipOpen(false);
				}
				this.mouseX = event.changedTouches[0].pageX;
				this.mouseY = event.changedTouches[0].pageY;
			});
		}
	},
	mounted(){
		this.ease = new CubicEase();
		this.ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
		this.initialize();
	}
}
</script>