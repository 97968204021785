<template>
    <div class="Loadingscreen">
        <div class="ls-container">

            <img class="ls-logo" :src="$getMedia('logos/Aliaxis_logo.png')" alt="">

            <svg class="ls-animation" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" xml:space="preserve">
                <path id="spinner" d="M40,72C22.4,72,8,57.6,8,40C8,22.4,22.4,8,40,8c17.6,0,32,14.4,32,32c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-15.4-12.6-28-28-28S12,24.6,12,40s12.6,28,28,28c1.1,0,2,0.9,2,2S41.1,72,40,72z">
                </path>
            </svg>

            <!-- Progress Visualization -> %-Text-->
            <p class="ls-text">0%</p>     

        </div>          
    </div>
</template>

<script>
export default {
    mounted(){
        setTimeout(() => {
            document.getElementsByClassName("ls-container")[0].style.opacity = 1; 
        }, 0);
    }
}
</script>

<style>
    .Loadingscreen{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FCFBFD;
    }
    .ls-container{
        opacity: 0;
        transition: 1s opacity ease-in;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .ls-logo{
        width: 350px;
        object-fit: contain;
    }
    .ls-animation{
        fill: var(--primaryColor);
        width: 150px;
        height: 150px;
        margin: auto;
        margin-top: 50px;
        display: block;
        animation: .6s linear infinite rotate;
    }
    @keyframes rotate { from { transform: rotate(0); } to { transform: rotate(360deg); }  }
    .ls-text{
        position: relative;
        top: 30px;
        font-size: 23px;
        text-align: center;
        margin: 0;
        transition: .2s opacity ease-in;
        color: var(--main-orange);
        font-weight: bold;
    }
    /*MOBILE*/
    @media (max-device-width: 900px){
        .ls-container{
            width: 250px;
        }
        .ls-logo{
            width: 200px;
        }
    }
</style>