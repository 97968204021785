<template>
    <div id="navigation">
        <div class="home-wrapper">
            <div class="nav-button nav-button-home" title="Home" @click="toDefaultView()">
                <div class="nav-button-icon homeIcon"></div>
            </div>
        </div>
        <div class="states-wrapper">
            <div v-if="availableSegments && availableSegments.water" class="nav-button nav-button-category" :class="{'nav-button-disable' : !isWaterEnabled}" @click="toggleWaterBool()" @mouseover="enableCategory('Wasser',true)" @mouseleave="enableCategory('Wasser',false)">
                <div class="nav-button-icon waterIcon"></div>
                <div class="nav-button-text">{{$t("Water")}}</div>
            </div>
            <div v-if="availableSegments && availableSegments.gas" class="nav-button nav-button-category" :class="{'nav-button-disable' : !isGasEnabled}" @click="toggleGasBool()"  @mouseover="enableCategory('Gas',true)" @mouseleave="enableCategory('Gas',false)">
                <div class="nav-button-icon gasIcon"></div>
                <div class="nav-button-text">{{$t("Gas")}}</div>
            </div>
            <div v-if="availableSegments && availableSegments.sewage" class="nav-button nav-button-category" :class="{'nav-button-disable' : !isSewageEnabled}" @click="toggleSewageBool()"  @mouseover="enableCategory('Abwasser',true)" @mouseleave="enableCategory('Abwasser',false)">
                <div class="nav-button-icon sewageIcon"></div>
                <div class="nav-button-text" style="margin-left: 3px;">{{$t("Sewage")}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";

export default {
    computed:{
        ...mapGetters(["isWaterEnabled","isSewageEnabled","isGasEnabled","defaultCamStats","availableSegments"])
    },
    methods:{
        ...mapMutations([
            "toggleWaterBool","toggleSewageBool","toggleGasBool","setGroundBool","setWaterBool",
            "setSewageBool","setGasBool","setInfoPanelOpen","setToolTipsDisabled"
    ]),
        enableCategory(name,mouseover){
            if(window.innerWidth > 900){ //Disable mouseover/mouseleave when on mobile
                var ease = new CubicEase();
                ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

                window.scene.getNodeByName(name).getChildMeshes().forEach(mesh => {
                    if(mesh.material.name != "steeDarkl" && mesh.material.name != "steel")
                        if(name != "Gas" || mesh.material.name != "darkPlastic")      
                            Animation.CreateAndStartAnimation("",mesh.material, "alpha", 60, 20, mesh.material.alpha, mouseover ? 0.6 : 1, 0, ease);
                });
            }
        },
        toDefaultView(){
            this.setGroundBool(false);
            this.setWaterBool(this.availableSegments.water);
            this.setSewageBool(this.availableSegments.sewage);
            this.setGasBool(this.availableSegments.gas);
            this.moveCameraToDefault();
        },
        moveCameraToDefault(){
            console.log("Start Camera Movement to orbital");
            var camera = window.scene.activeCamera;
       
            // Set keyboard controls
            camera.inputs.attached.keyboard.keysDown = [40];
            camera.inputs.attached.keyboard.keysUp = [38];
            camera.inputs.attached.keyboard.keysLeft = [37];
            camera.inputs.attached.keyboard.keysRight = [39];
                
            // Camera Animaiton
            var ease = new CubicEase();
        	ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            
            let time = 200;

            var alphaAnimation = new Animation("", "alpha", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
            alphaAnimation.setEasingFunction(ease)
            alphaAnimation.setKeys([
                {frame: 0, value: camera.alpha % (Math.PI*2)},
                {frame: time, value: this.defaultCamStats.alpha.value}
            ]);

            var betaAnimation = new Animation("", "beta", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
            betaAnimation.setEasingFunction(ease)
            betaAnimation.setKeys([
                {frame: 0, value: camera.beta % (Math.PI*2)},
                {frame: time/1.5, value: this.defaultCamStats.beta.value},
                {frame: time, value: this.defaultCamStats.beta.value}
            ]);

            var radiusAnimation = new Animation("", "radius", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
            radiusAnimation.setEasingFunction(ease)
            radiusAnimation.setKeys([
                {frame: 0, value: camera.radius},
                {frame: time, value: this.defaultCamStats.radius.value}
            ]);

            var targetAnimation = new Animation("", "target", 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
            targetAnimation.setEasingFunction(ease)
            targetAnimation.setKeys([
                {frame: 0, value: camera.target},
                {frame: time, value: this.defaultCamStats.target}
            ]);
            
            camera.animations = [targetAnimation, radiusAnimation, alphaAnimation, betaAnimation];
            window.scene.beginAnimation(camera, 0, time); 
            
            // Disable Small Pins immediately
            window.scene.getNodeByName("Pins").getChildren().forEach((children, index) => {
                if(index != 0)
                    this.disablePin(children.name,0)
            });

            // At Animationend (ca. 1.2 Seconds)
            setTimeout(() => {
                //Disable small Pins / Enable Main Pin
                this.disablePin("pin_plane_1",1)
                
                // Enable Environment (Clouds/Birds)    
                let environmentNode = window.scene.getNodeByName("Environment");
                environmentNode.getChildren().forEach(mesh => {
                    Animation.CreateAndStartAnimation("", mesh, "visibility", 60, 180, mesh.visibility, 0.8, 0, ease); 
                });

                // Set default camera limits (prevent user to look underneath island)
                camera.lowerRadiusLimit = this.defaultCamStats.radius.lowerLimit;
                camera.upperRadiusLimit = this.defaultCamStats.radius.upperLimit;
                camera.lowerAlphaLimit = this.defaultCamStats.alpha.lowerLimit;
                camera.upperAlphaLimit = this.defaultCamStats.alpha.upperLimit;
                camera.lowerBetaLimit = this.defaultCamStats.beta.lowerLimit;
                camera.upperBetaLimit = this.defaultCamStats.beta.upperLimit;
            }, 1200);
            
            camera.invertRotation = false;

            // Disable Navigation UI
            let navigationUI = document.getElementById("navigation");
            navigationUI.style.opacity = 0;
            navigationUI.style.pointerEvents = "none";

            // Disable tooltips
            this.setToolTipsDisabled(true);

            // Close Info Panel
            this.setInfoPanelOpen(false);
        },
        disablePin(name,visibility){
            var ease = new CubicEase();
        	ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

            let pin = window.scene.getMeshByName(name);
            if(visibility != 0)
                pin.setEnabled(visibility != 0);
            Animation.CreateAndStartAnimation("", pin, "visibility", 60, 40, pin.visibility, visibility, 0, ease, ()=>{
                pin.setEnabled(visibility != 0);
            }); 
        }
    }
}
</script>

<style>
#navigation{
    display: inline-flex;
    opacity: 0;
    pointer-events: none;
    margin: 15px;
    transition: opacity 1s ease;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#navigation>*:not(:last-child){
    margin-right: 1rem;
}
#navigation .states-wrapper{
    display: flex;
    border-radius: 10rem;
    padding: 5px;
    background-color: var(--bg-light);
}
#navigation .states-wrapper button:not(:last-child){
    margin-right: 0.5rem;
}
#navigation .home-wrapper{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

/* Button CSS */
.nav-button{
    display: flex;
	align-items: center;	
	padding: 5px 16px 5px 10px;
    margin-right: 6px;
	border-radius: 50px;
	border: 1px solid var(--bg-light);
	cursor: pointer;
    color: white;
}
.nav-button:last-child{
    margin-right: 0px; 
}
.nav-button-home{
    background: var(--bg-light);
	transition: background .2s ease, border-color .2s ease;
    height: 43px;
	width: 43px;
    padding: 0;
	justify-content: center;
}
.nav-button-home:hover{
    background: rgb(210,210,210);
	border-color: rgb(210,210,210);
}
.nav-button-category{
    background: linear-gradient(90deg,var(--color-lightblue) 20%,var(--color-blue) 90%);
}
.nav-button-category:hover{
    background: linear-gradient(90deg,#2682c477 20%,#2248a177 90%);
}
.nav-button-icon{
    height: 24px;
	width: 24px;
}
.nav-button-text{
    font-size: 16px;
}  
.nav-button-disable{
    background: transparent;
	border-color: var(--bg-dark);
	color: var(--bg-dark);
}
.nav-button-disable:hover{
    background: linear-gradient(90deg,#2682c428 20%,#2248a12a 90%);
    border-color: var(--color-blue);
	color: var(--color-blue);
}

/* Icons */
.homeIcon{
    background-image: url('/src/assets/icons/home.svg');
    opacity: 0.7;
    width: 25px;
    height: 25px;
    background-size: 100%;
}
.waterIcon{
    background-image: url('/src/assets/icons/ic_water.svg');
}
.sewageIcon{
    background-image: url('/src/assets/icons/ic_pipe.svg');
}
.gasIcon{
    background-image: url('/src/assets/icons/ic_fire.svg');
}
.nav-button-disable .waterIcon{
    background-image: url('/src/assets/icons/ic_water_blue.svg');
}
.nav-button-disable .sewageIcon{
    background-image: url('/src/assets/icons/ic_pipe_blue.svg');
}
.nav-button-disable .gasIcon{
    background-image: url('/src/assets/icons/ic_fire_blue.svg');
}

/*MOBILE*/
@media (max-device-width: 900px){
    .nav-button-category:hover{
        background: linear-gradient(90deg,var(--color-lightblue) 20%,var(--color-blue) 90%);
    }
    .nav-button-disable{
        background: transparent !important;
        border-color: var(--bg-dark) !important;
        color: var(--bg-dark) !important;
    }
    .nav-button-text{
        font-size: 13px;
    }  
}
</style>