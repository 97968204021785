<template>
    <div id="product-info-panel">
        <div v-if="product" class="wrapper">

            <!-- Close-Button -->
            <button class="closeBtn-light" @click="setInfoPanelOpen(false)">
                <img class="closeBtn-img" :src="$getMedia('icons/ic_cross.svg')" alt="">
            </button>

            <!-- Aliaxis-Logo -->
            <img class="pic-logo" :src="$getMedia('logos/aliaxis_logo-white.svg')" width="100px" alt="">

            <!-- Header (Product-Logo, Name, Group, Code) -->
            <div id="product-info-header">
                <div class="left">
                    <img class="logo" :src="product.data.logo" alt="">
                    <h2 v-if="product.data.group != ''" id="info-panel-h2">{{product.data.group}}</h2>
                    <h3 v-if="product.data.code != ''" id="info-panel-h3">{{product.data.code}}</h3>
                    <h1 v-if="product.data.title != ''" id="info-panel-h1">{{product.data.title}}</h1>
                </div>
                <div class="right">
                    <img v-if='product.data.img && product.data.img != ""' id="info-panel-img" :src='product.data.img' alt="">
                </div>
            </div>

            <!-- Body (Button to productpage, videos) -->
            <div class="product-info-body">
                <div v-show='product.data.description' class="description-wrapper"></div>
                <div class="link-wrapper">
                    <a v-if='product.data.button &&  product.data.button.url != ""' :href="product.data.button.url" target="_blank" rel="noopener noreferrer">{{ product.data.button.label }}</a>
                </div>
                <div v-if='product.data.video && product.data.video != ""'>
                    <iframe v-if='product.data.video.includes("youtube")' :src='product.data.video.replace("watch?v=","embed/")' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <video v-else :src='product.data.video' controls preload/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
    watch:{
        product(){
            console.log("product:",this.product);
            setTimeout(() => {
                if(this.product && this.product.data.description != ""){
                    document.querySelector("#product-info-panel .product-info-body .description-wrapper").innerHTML = this.product.data.description.trim();
                } else {
                    document.querySelector("#product-info-panel .product-info-body .description-wrapper").innerHTML = "";
                }
            }, 100);
        }
    },
    computed:{
        ...mapGetters(['infoPanelData','activeLanguage']),
        product(){
            return this.infoPanelData ? {
                _ID: this.infoPanelData._ID,
                _segment: this.infoPanelData._segment,
                data: this.infoPanelData.data.find(data => data.lang == this.activeLanguage)
            } : null;
        },
    },
    methods:{
        ...mapMutations(["setInfoPanelOpen"]),
    }
}
</script>

<style>
    #product-info-panel{
        width: 500px;
        height: calc(100vh - 4rem); /* Have to subtract Padding to get 100vh */
        padding: 2rem;
        position: relative;
        display: inline-block;
        background-color: white;
        transition-duration: 0.4s;
        opacity:1;
        pointer-events: all;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #product-info-panel::after{
        content:"";
        position: absolute;
        height: 2000px;
        width: 2000px;
        left: 50%;
        top: -1900px;
        transform: translateX(-50%);
        border-radius: 50%;
        background: linear-gradient(to right, var(--color-lightblue),var(--color-blue));
    }
    #product-info-header, .product-info-header{
        display: flex;
        margin-top: 8rem;
        justify-content: space-between;
    }
    #product-info-panel .logo{
        height: 48px;
        margin-bottom: 25px;
        pointer-events: none;
    }
    #product-info-panel h1 {
        color: var(--color-lightblue);
        font-size: 1.4rem;
        line-height: 1.25;
        margin:0px;
        margin-top: 8px;
    }
    #product-info-panel h3 {
        font-size: 0.6rem;
        margin:0px;
        margin-top: 8px;
        line-height: 0;
    }
    #product-info-panel .closeBtn-light{
        position: absolute; 
        top: 0.75rem;
        right: 0.75rem;
        height: 35px;
        width: 35px;
        border-radius: 16px;
        border-color: transparent;
        background-color: transparent;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0;
        cursor: pointer;
    }
    #product-info-panel .closeBtn-light>img{
        height: 100%;
        width: 100%;
    }
    .pic-logo{
        position: absolute; 
        top:1.5rem; 
        z-index:1; 
        left: 50%; 
        transform: translateX(-50%); 
        pointer-events: none;
        width: auto;
    }
    #product-info-header, .product-info-header{
        margin-bottom: 2rem;
    }
    #product-info-header .left, .product-info-header .left{
        width: 60%;
        padding-right: 24px;
    }
    #product-info-header .right, .product-info-header .right{
        width: 40%;
    }
    .product-info-body .link-wrapper{
        display: flex;
        margin-bottom: 2rem;
    }
    .product-info-body .link-wrapper a{
        font-size: 1rem;
        font-weight: 600;
        color: white;
        text-decoration-line: none;
        padding: 0.8125rem 1.5rem;
        background-color: rgb(94,94,94);
    }
    .product-info-body .link-wrapper a:not(:last-child){
        margin-right: 4px;
    }
    .product-info-body .link-wrapper a:hover{
        color: white;
        background-color: #383838;
    }
    .product-info-body iframe{
        width: 100%;
        height: 245px;
    }
    .product-info-body video{
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
        object-position: center top;
    }
    .product-info-footer{
        position: absolute;
        bottom: 0;
        left:0;
        right:0;
    }
    #info-panel-img , .info-panel-img{
        height:100%;
        width: 100%;
        object-fit: contain;
        pointer-events: none;
    }
@media (max-width: 900px){
    #product-info-panel, .product-info-panel{
        width: calc(100vw - 4rem); /* have to subtract padding to get 100vw/vh */
    }
}
</style>