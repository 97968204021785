<template>
    <transition name="slideUp">
        <div class="Tooltip" id="tooltip" v-show="isTooltipOpen" @click="openInfoPanel()" :style="getStyle">
            <img class="tooltip-logo" :src="productLogo" alt="">
            <h2 class="tooltip-name">{{ productTitle }}</h2>
        </div>
    </transition>
</template>

<script>
import {mapGetters,mapMutations} from "vuex";

export default {
    computed:{
        ...mapGetters(['isTooltipOpen','tooltipData','activeLanguage','products']),
        productInfo(){
            if(this.tooltipData){
                var productInfo = this.products.find(p => p.DO_NOT_CHANGE_ID == this.tooltipData.productID).data.find(data => data.lang == this.activeLanguage);
                if(!productInfo) // Fallback = english products
                    return this.products.find(p => p.DO_NOT_CHANGE_ID == this.tooltipData.productID).data.find(data => data.lang == 'en');

                return productInfo
            }
            return undefined
        },
        productTitle(){
            if(this.productInfo)
                return this.productInfo.title;
            return ''
        },
        productLogo(){
            if(this.productInfo)
                return this.productInfo.logo;
            return ''
        },
        getStyle(){
            if(this.tooltipData)
                return {top: this.tooltipData.top, left: this.tooltipData.left}
            return {}
        }
    },
    methods: {
        ...mapMutations(['setInfoPanelData', 'setInfoPanelOpen', 'setTooltipOpen']),
        openInfoPanel(){
            this.setInfoPanelData(this.products.find(product => product.DO_NOT_CHANGE_ID == this.tooltipData.productID));		
			this.setInfoPanelOpen(true);
			this.setTooltipOpen(false);
        }
    }
}
</script>

<style>
.Tooltip{
    position: absolute;  
    background-color: transparent;
    cursor: pointer;
    max-width: 320px;
    min-width: 180px;
    border-radius: 2px;
    background-color: white;
    padding: 20px;
    padding-right: 40px;
    padding-bottom: 10px;
}
.tooltip-name {
    position: relative;
    font-size: 1rem;
    user-select: none;
}
.tooltip-name::after{
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    top: 0;
    right: -16px;
    background-image: url("/src/assets/icons/tooltip_ic_openInfo.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
}
.tooltip-logo{
    width: 60px;
    display: block;
    margin-bottom: 10px;
}

/* Open-Animation */
.slideUp-enter-active, .slideUp-leave-active{
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.slideUp-enter-from, .slideUp-leave-to {
  transform: translateY(28px);
  opacity: 0;
}
</style>