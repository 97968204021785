<template>
    <canvas id="renderCanvas" ref="renderCanvas">
        <slot v-if="loaded"/>
    </canvas> 
</template>

<script>
import {mapMutations} from 'vuex'

import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { Color3, Vector3 } from "@babylonjs/core/Maths/math";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
// import { WebGPUEngine } from '@babylonjs/core/Engines/webgpuEngine';
import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/index';
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';

export default {
    data(){
        return {
            loaded: false
        }
    },
    methods: {
        ...mapMutations(['setLoadingscreenVisible','setDefaultCamStats']),
        async initialize() {
            await this.initializeEngine();
            this.initializeScene();
            this.initializeAssetsManager();
            this.initializeCamera();
            this.initPostProcessing();

            this.loaded = true;
        },
        async initializeEngine(){
            // const webGPUSupported = await WebGPUEngine.IsSupportedAsync;
            // if (webGPUSupported) {
            //     window.engine = new WebGPUEngine(document.getElementById("renderCanvas"), { adaptToDeviceRatio: true, antialias: true });
            //     await window.engine.initAsync();
            // } else {
                window.engine = new Engine(document.getElementById("renderCanvas"), true, undefined, true); // Fall back to the normal engine if WebGPU is not supported
            // }
            
            // window.engine.enableOfflineSupport = true;
            // BABYLON.Database.IDBStorageEnabled = true;
            // Engine.OfflineProviderFactory = (urlToScene, callbackManifestChecked, disableManifestCheck) => {
            //     return new BABYLON.Database(urlToScene, callbackManifestChecked, true);
            // };

            function customLoadingScreen() {}
            customLoadingScreen.prototype.displayLoadingUI = () => { this.setLoadingscreenVisible(true);};
            customLoadingScreen.prototype.hideLoadingUI = () => { this.setLoadingscreenVisible(false);};
            customLoadingScreen.prototype.setLoadingUIText = (text) => {  document.getElementsByClassName("ls-text")[0].innerHTML = text; }
            window.engine.loadingScreen = new customLoadingScreen();
            
            window.addEventListener("resize", () => window.engine.resize());
        },
        async initializeScene(){
            window.scene = new Scene(window.engine)
            window.scene.useRightHandedSystem = false;
            window.scene.clearColor = new Color3(182/255,191/255,229/255);
			window.scene.fogMode = Scene.FOGMODE_LINEAR; // Dezenter Fog -> Kann auch raus, wenn die Performance darunter leidet
        }, 
        initializeAssetsManager(){
            window.assetsManager = new AssetsManager(window.scene)

            window.assetsManager.onTaskErrorObservable.add(function (task) {
                console.log('Babylon Task failed', task.errorObject.message, task.errorObject.exception);
            });

            window.assetsManager.onProgress = (remainingCount, totalCount) => {
                window.engine.loadingScreen.setLoadingUIText((100 * (totalCount - remainingCount) / totalCount).toFixed(0) + "%");
            };

            window.assetsManager.onFinish = () => {
                // this.optimizeScene();
                this.playIntroAnimation();
                this.startRenderLoop();
            };
        },
        initializeCamera(){  
            let camera = new ArcRotateCamera("Camera", Math.PI * 0.7 - 0.1, Math.PI / 4 - 0.1, 29, new Vector3(0,-5,0), window.scene); 
			camera.invertRotation = false;
			camera.angularSensibilityX = 1000;
			camera.angularSensibilityY = 1000;
			camera.fov = 1;
			camera.minZ = 0.2;
            camera.maxZ = 50;
            camera.upperBetaLimit = 1.1;
            camera.inertia = 0.8;

            // set default camera stats
			var stats = {
				radius: {
					upperLimit: camera.upperRadiusLimit,
					lowerLimit: camera.lowerRadiusLimit,
					value: 31,
				},
				alpha: {
					upperLimit: camera.upperAlphaLimit,
					lowerLimit: camera.lowerAlphaLimit,
					value: camera.alpha + 0.1,
				},
				beta: {
					upperLimit: camera.upperBetaLimit,
					lowerLimit: camera.lowerBetaLimit,
					value: camera.beta + 0.1,
				},
				target: camera.target,
			}
			this.setDefaultCamStats(stats);
		},
        optimizeScene(){
            // Optimize Meshes
            window.scene.meshes.forEach(mesh => {
                if(!mesh.name.includes("pin_plane") && !mesh.name.includes("clouds")){
                    mesh.isPickable = false;
                    mesh.freezeWorldMatrix();
                }
                
                if(mesh.material && mesh.material.name != "Graben" && mesh.material.name != "house_1" && !mesh.material.name.includes("_cloneForAnimation")){
                    mesh.material.freeze();
                    mesh.material.backFaceCulling = true;
                }
            });

            // Optimize Scene
            window.scene.autoClear = false;
            window.scene.autoClearDepthAndStencil = false;
            window.scene.renderTargetsEnabled = false;
        },
        playIntroAnimation(){
            var ease = new CubicEase();
            ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

            let camera = window.scene.activeCamera;
            Animation.CreateAndStartAnimation("",camera, "radius", 60, 240, camera.radius, 31, 0, ease);
            Animation.CreateAndStartAnimation("",camera, "alpha", 60, 240, camera.alpha, camera.alpha + 0.1, 0, ease);
            Animation.CreateAndStartAnimation("",camera, "beta", 60, 240, camera.beta, camera.beta + 0.1, 0, ease, () => {
                camera.attachControl(this.$refs.renderCanvas, true, false, 4);
                camera.inputs.attached.mousewheel.detachControl(this.$refs.renderCanvas);
            });
        },
        initPostProcessing(){
            var pipeline = new DefaultRenderingPipeline("defaultPipeline", true, window.scene, [window.scene.activeCamera]);
            pipeline.samples = 6;
            pipeline.fxaaEnabled = true;
            pipeline.imageProcessing.toneMappingEnabled = false;
            pipeline.imageProcessing.contrast = 1.8;
            pipeline.imageProcessing.exposure = 0.86;
            pipeline.sharpenEnabled = 1;

            // Depth of field
            // pipeline.depthOfFieldBlurLevel = BABYLON.DepthOfFieldEffectBlurLevel.Medium;
            // pipeline.depthOfFieldEnabled = true;
            // pipeline.depthOfField.focalLength = 30;
            // pipeline.depthOfField.fStop = 3;
            // pipeline.depthOfField.focusDistance = 150;

            // SSAO
            // let ssao = new BABYLON.SSAO2RenderingPipeline("ssao", window.scene, {
            //     ssaoRatio: 1, // Ratio of the SSAO post-process, in a lower resolution
            //     blurRatio: 3    // Ratio of the combine post-process (combines the SSAO and the scene)
            // }, [window.scene.activeCamera]);
            // ssao.samples = 10;
            // ssao.totalStrength = 0.95;
            // ssao.base = 0.1;
            // ssao.maxZ = 11;
            // ssao.minZAspect = 0.12;
            // ssao.radius = 2.555;
            // ssao.epsilon = 0.02;
            // ssao.bilateralSamples = 12;
            // ssao.bilateralTolerance = 0.08;
        },
        startRenderLoop () {
			window.engine.runRenderLoop(() => window.scene.render() );
       },
    },
    mounted(){
        this.initialize();
    },
    destroyed(){
        window.engine.dispose();
        window.scene.dispose();
    }
}
</script>


<style>
#renderCanvas {
    transition: 1s filter ease, .25s transform ease-in-out;
    width: 100%;
    height: 100%;
    outline: none;
    pointer-events: all;
}

.title {
    font-size: 1em !important;
    text-align: left !important;
    line-height: 1.2 !important;
    margin-bottom: 0px !important;
}
</style>
