<template>
    
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import { Vector3, Color3 } from "@babylonjs/core/Maths/math";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Texture } from "@babylonjs/core/Materials/Textures/texture"
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from '@babylonjs/core/Actions/directActions';
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import "@babylonjs/core/Culling/ray";

export default {
    props: ["id","position","alpha","beta","target"],
    data(){
		this.ease = null;
        return {}
    },
    computed:{
        ...mapGetters(['isGroundOpen','isFirstTimeTutorial'])
    },
    methods:{
        ...mapMutations(['setGroundBool','setTutorialVisible',"setToolTipsDisabled"]),
        initPin(){
            let size = 1.8;
            let sizeSmall = 0.5;
            var plane = MeshBuilder.CreatePlane("pin_plane_" + this.id, {size: this.id==1 ? size : sizeSmall}, window.scene);
            plane.position = this.position;
            plane.billboardMode = 7; // (7 = Mesh.BILLBOARDMODE_ALL)
            plane.material = new StandardMaterial("pinMat", window.scene);
            plane.material.opacityTexture = plane.material.diffuseTexture = new Texture(this.$getMedia("textures/btn_pin.png"), window.scene);
            plane.material.emissiveColor = new Color3(255,255,255);
            plane.material.disableLighting = true;
            plane.parent = window.scene.getNodeByName("Pins");

            // Disable all Pins excpt the first one
            if(this.id != 1){
                plane.setEnabled(false);
                plane.visibility = 0;
            }

            // Interaction
            plane.actionManager = new ActionManager(window.scene);
            plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
                Animation.CreateAndStartAnimation("", plane, "scaling" , 60, 20, plane.scaling, plane.scaling.scale(1.2), 0, this.ease);
            }));
            plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
                Animation.CreateAndStartAnimation("", plane, "scaling" , 60, 20, plane.scaling, Vector3.One(), 0, this.ease);
            }));
            plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, () => {
                if(this.id == 1) this.setGroundBool(true); // Open Ground when pin has ID 1 (When user clicks the first pin)
                this.moveCameraToPosition(plane);
            }));
        },
        moveCameraToPosition(pin){
            console.log("Start Camera Movement to " + pin.name);
            const camera = window.scene.activeCamera;

            // Set keyboard controls
            camera.inputs.attached.keyboard.keysDown = [38];
			camera.inputs.attached.keyboard.keysUp = [40];
			camera.inputs.attached.keyboard.keysLeft = [39];
			camera.inputs.attached.keyboard.keysRight = [37];

            // Camera Animation to pin
            if(this.id != 1){
                this.disablePin(pin.name, 0, 20); // Disable pin I clicked on

                // Enable all pins except the first one
                window.scene.getNodeByName("Pins").getChildren().forEach((child, index) => {
                    if(index != 0 && child.name != pin.name)
                        this.disablePin(child.name, 1, 20);
                });
                
                let time = 140;
                Animation.CreateAndStartAnimation("",camera, "target", 60, time, camera.target, pin.position.add(new Vector3(0,1,0)), 0, this.ease);
                Animation.CreateAndStartAnimation("",camera, "radius", 60, time, camera.radius, 0, 0, this.ease);
                Animation.CreateAndStartAnimation("",camera, "alpha", 60, time, camera.alpha % (Math.PI*2), this.alpha, 0, this.ease);
                Animation.CreateAndStartAnimation("",camera, "beta", 60, time, camera.beta % (Math.PI*2), this.beta, 0, this.ease);
            }
            
            // Camera Animation to Island Center
            if(this.id == 1){   
                // Disable pin 1 (Pin to island center)
                this.disablePin(pin.name, 0, 20); // Disable pin I clicked on

                // Disable Environment (Clouds/Birds)
                let environmentNode = window.scene.getNodeByName("Environment");
                environmentNode.getChildren().forEach(mesh => {
                    Animation.CreateAndStartAnimation("",mesh,"visibility", 60, 60, mesh.visibility, 0, 0, this.ease);
                });

                // Open Limit ranges
                camera.lowerRadiusLimit = camera.upperRadiusLimit = null;
                camera.lowerAlphaLimit = camera.upperAlphaLimit = null;
                camera.lowerBetaLimit = 0
                camera.upperBetaLimit = Math.PI;
                camera.invertRotation = true;

                // Animate to Center
                let time = 180;
                let timeSlow = 260;

                var alphaAnimation = new Animation("", "alpha", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
                alphaAnimation.setEasingFunction(this.ease)
                alphaAnimation.setKeys([
                    {frame: 0, value: camera.alpha % (Math.PI*2)},
                    {frame: camera.beta > 0.9 ? timeSlow : time, value: this.alpha}
                ]);

                var betaAnimation = new Animation("", "beta", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
                betaAnimation.setEasingFunction(this.ease)
                betaAnimation.setKeys([
                    {frame: 0, value: camera.beta % (Math.PI*2)},
                    {frame: camera.beta > 0.9 ? timeSlow/2 : time/3 , value: camera.beta > 0.9 ? 0.7 : camera.beta % (Math.PI*2)},
                    {frame: camera.beta > 0.9 ? timeSlow : time, value: this.beta}
                ]);

                var radiusAnimation = new Animation("", "radius", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
                radiusAnimation.setEasingFunction(this.ease)
                radiusAnimation.setKeys([
                    {frame: 0, value: camera.radius},
                    {frame: camera.beta > 0.9 ? timeSlow : time, value: 0}
                ]);

                var targetAnimation = new Animation("", "target", 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
                targetAnimation.setEasingFunction(this.ease)
                targetAnimation.setKeys([
                    {frame: 0, value: camera.target},
                    {frame: camera.beta > 0.9 ? timeSlow : time, value: this.target}
                ]);
    
                camera.animations = [targetAnimation, radiusAnimation, alphaAnimation, betaAnimation];
                window.scene.beginAnimation(camera, 0, camera.beta > 0.9 ? timeSlow : time);
                
                // At Animationend (ca. 1.5 Seconds)
                setTimeout(() => {
                    // Enable all Pins except the first one (Pin to island center) and the pin the user is standing on (pin4)
                    window.scene.getNodeByName("Pins").getChildren().forEach((children, index) => {
                        if(index != 0 && index != 3)
                            this.disablePin(children.name, 1);
                    });

                    // Enable tooltips
                    this.setToolTipsDisabled(false);

                    // Enable Navigation UI
                    let navigationUI = document.getElementById("navigation");
                    navigationUI.style.opacity = 1;
                    navigationUI.style.pointerEvents = "all";
                    if(this.isFirstTimeTutorial)
                        this.setTutorialVisible(true);
                }, 2800);
            }
        },
        disablePin(name,visibility,time=40){
            let pin = window.scene.getMeshByName(name);
            if(visibility != 0)
                pin.setEnabled(visibility != 0);
            Animation.CreateAndStartAnimation("",pin,"visibility",60,time,pin.visibility,visibility,0,this.ease,()=>{
                pin.setEnabled(visibility != 0);
            }); 
        }
    },
    mounted(){
        this.ease = new CubicEase();
		this.ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

        setTimeout(() => {this.initPin() }, 0);
    }
}
</script>