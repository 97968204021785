<template>
    <transition name="fade">
        <div v-show="isTutorialVisible" id="tutorial">

            <!-- Background -->
            <div class="bg" @click="setTutorialVisible(false)"></div>

            <div class="window-wrapper">

                <!-- Close-Button -->
                <button class="exit fab m" @click="setTutorialVisible(false)">
                    <img :src="$getMedia('icons/close.svg')" alt="">
                </button>

                <div class="tutorial-wrapper">

                    <!-- Page-Content -->
                    <div class="tutorial-pages" :style="{left: pageIndex * -100 + '%'}">
                        <div v-for="page in pages" class="tutorial-page">
                            <div class="media-wrapper">
                                <img v-if="page.media.type == 'img'" :src="$getMedia('tutorial/' + page.media.url)" alt=""> 
                                <video v-if="page.media.type == 'video'" :src="$getMedia('tutorial/' + page.media.url)" autoplay loop muted/>
                                <div v-if="page.media.type == 'none'" class="no-media">
                                    <img :src="$getMedia('logos/Aliaxis_logo.png')" alt=""> 
                                </div>
                            </div>

                            <!-- Text -->
                            <p>{{ $t(page.text) }}</p>
                        </div>
                    </div>
                    

                    <!-- Navigation -->
                    <div class="tutorial-nav">
                        <div class="top">

                            <!-- Button-Left -->
                            <button class="fab m" @click="movePageAmount(-1)" :style="{opacity: pageIndex > 0 ? 1 : 0.2, pointerEvents: pageIndex > 0 ? '' : 'none'}">
                                <img style="transform: rotate(180deg)" :src="$getMedia('icons/chevron-right.svg')" alt="">
                            </button>

                            <!-- Navigation-Dots -->
                            <div class="tutorial-nav-middle">
                                <div v-for="page in pages" :key="page.id" class="dot" :class="{ 'enabled': pageIndex == page.id }" @click="moveToPage(page.id)"></div>
                            </div>

                            <!-- Button-Right -->
                            <button v-show="pageIndex < (pageCount - 1)" class="fab m" @click="movePageAmount(1)">
                                <img :src="$getMedia('icons/chevron-right.svg')" alt="">
                            </button>
                            <button v-show="pageIndex == (pageCount - 1)" class="primary" @click="setTutorialVisible(false)">
                                {{ $t("Complete") }}
                            </button>
                        </div>

                        <!-- Complete-Button -->
                        <div class="bottom">
                            <button title="Skip Tutorial" class="tertiary" @click="setTutorialVisible(false)">
                                {{ $t("Complete") }}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import pages from "/src/assets/tutorial/tutorial.json";

export default {
    data: () => ({
        pageIndex: 0
    }),
    computed: {
        ...mapGetters(["isTutorialVisible"]),
        pages() {
            return pages;
        },
        pageCount() {
            return pages.length;
        }
    },
    watch: {
        isTutorialVisible() {
            this.setFirstTimeTutorialBoolean(false);
            if (this.isTutorialVisible)
                this.pageIndex = 0;
        }
    },
    methods: {
        ...mapMutations(["setTutorialVisible", "setFirstTimeTutorialBoolean"]),
        movePageAmount(number) {
            this.pageIndex += number;
        },
        moveToPage(id) {
            this.pageIndex = id;
        }
    }
}
</script>

<style scoped>
#tutorial {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#tutorial .bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.window-wrapper {
    position: absolute;
    overflow: hidden;
}

.tutorial-wrapper {
    height: 35rem;
    width: 45rem;
    background-color: var(--bg-light);
    border-radius: 0.25rem;
    overflow: hidden;
}

.tutorial-pages{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}

.tutorial-page {
    width: 100%;
    height: 100%;
    flex:0 0 auto;
}

.tutorial-page .media-wrapper {
    position: relative;
    width: 100%;
    height: 60%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.tutorial-page .media-wrapper>* {
    width: 100%;
}

.tutorial-page .media-wrapper .no-media {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tutorial-page .media-wrapper .no-media img {
    height: 64px;
}

.tutorial-page .media-wrapper img {
    object-fit: cover;
}

video {
    object-fit: cover;
}

.tutorial-page p {
    margin: 0;
    padding: 1rem 4rem;
}

.tutorial-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 2rem;
}

.tutorial-nav .top {
    height: 44px;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tutorial-nav .bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.tutorial-nav-middle {
    display: flex;
    align-items: center;
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin: 0 0.2rem;
    border: solid 3px var(--color-lightblue);

    cursor: pointer;
    transition: .3s ease;
}

.dot.enabled {
    background-color: var(--color-lightblue);
}

/* Tutorial Fade Animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* customs */
video[id="2"] {
    object-position: top left;
}

/*MOBILE*/
@media (max-device-width: 900px) {
    .tutorial-wrapper {
        width: 90vw;
        height: 80vh;
    }

    .media-wrapper {
        height: 50% !important;
    }
}</style>