<template>
	<div class="help-menu-wrapper">
		<!-- Help-Button -->
		<button title="Help" class="fab m" @click="setTutorialVisible(true)" >
			<img :src="$getMedia('icons/help-circle-outline.svg')" alt="">
		</button>

		<!-- Language-Button-->
		<button v-if="languages" title="Language" class="fab m" @click="languagePopup=!languagePopup" >
			<img :src="$getMedia('icons/translate.svg')" alt="">
			<!-- <img class="indicator" :src='$getMedia("flags/" + activeLanguage + ".png")' alt=""> -->
			<div v-show="languagePopup" class="button-popup" @mouseleave="languagePopup = false">
				<button v-for="(language,index) in languages" :key="index" class="popup-item" :title="$t(getLanguageLabel(language))" @click="changeLanguage(language)">
					<!-- <img class="flag" :src="$getMedia('flags/' + language + '.png')" alt=""> -->
					{{$t(language)}}
				</button>
			</div>
		</button>
	</div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'

export default {
	data: () => ({
		queryRegionID: "region",
		languagePopup: false,
	}),
	computed: {
		...mapGetters(["activeLanguage","languages"]),
	},
	methods: {
		...mapMutations(["setTutorialVisible",'setActiveLanguage']),
		getLanguageLabel(code){
			switch(code){
				case 'de': return "German";
				case 'en': return "English";
				case 'fr': return "French";
				case "it": return "Italian";
				default: return code;
			}
		},
		changeLanguage(language){
			this.setActiveLanguage(language)
			this.$root.$i18n.locale = language;
		}
	}
}
</script>

<style>
.help-menu-wrapper{
	position: absolute;
	right: 20px;
	bottom: 20px;
	display: flex;
	flex-direction: row-reverse;
	gap: 20px;
}
button[title="Help"],button[title="Language"] {
	position: relative;
	background: var(--color-blue) !important;
	transition: background .3s ease;
}
button[title="Help"]:hover,button[title="Language"]:hover {
	background: var(--color-blue-hover) !important;
}
button[title="Help"] img ,button[title="Language"] img{
	max-width: none;
	filter: invert(1);
	width: 100%;
}
button[title="Language"] img{
	width: 80%;
}
button[title="Language"] img.indicator{
	position: absolute;
	top: 0;
	right: 0;
	width: 1.3rem;
	height: 1.3rem;
	transform: translate(30%,-30%);
	filter: invert(0);
	border: 2px solid var(--color-blue);
	border-radius: 50%;
}
.button-popup{
	position: absolute;
	top:0;
	transform: translate(0,calc(-100% - 10px));
	padding: 10px;
	background-color: var(--bg-light);
	border-radius: 0.25rem;
}
.button-popup button{
	width: 100%;
	background-color: transparent;
	text-align: left;
	padding: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}
.button-popup button img.flag{
	filter: invert(0);
	height: 24px;
	width: 24px;
}
.button-popup button.popoup-item:hover{
	background-color: var(--bg-light-hover);
	color: var(--color-lightblue);
}
</style>