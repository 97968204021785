import { createStore } from "vuex";

export default createStore({
	state: {
		// Language
		activeLanguage: 'de',
		languages: ['de'],

		// Tutorial
		tutorialVisible: false,
		firstTimeTutorial: true,
		activeTutorialPage: 0,

		// Categories
		availableSegments: null,
		waterEnabled: true,
		sewageEnabled: true,
		gasEnabled: true,

		// InfoPanel
		isInfoPanelOpen: false,
		infoPanelData: null,

		// Tooltip
		isTooltipOpen: false,
		tooltipData: null,
		toolTipsDisabled: true,

		// Products data
		products: [],

		isGroundOpen: false,
		loadingscreenVisible: true,
		defaultCamStats: {
			radius: {
				upperLimit: null,
				lowerLimit: null,
				value: null,
			},
			alpha: {
				upperLimit: null,
				lowerLimit: null,
				value: null,
			},
			beta: {
				upperLimit: null,
				lowerLimit: null,
				value: null,
			},
			target: null,
		}
	},
	getters: {
		toolTipsDisabled: (state) => { return state.toolTipsDisabled; },
		tooltipData: (state) => { return state.tooltipData; },
		loadingscreenVisible: (state) => { return state.loadingscreenVisible; },
		activeLanguage: (state) => {return state.activeLanguage},
		languages: (state) => {return state.languages},
		products: (state) => {return state.products},
		availableSegments: (state) => {return state.availableSegments},
		isWaterEnabled: (state) => {return state.waterEnabled},
		isSewageEnabled: (state) => {return state.sewageEnabled},
		isGasEnabled: (state) => {return state.gasEnabled},
		isGroundOpen: (state) => {return state.isGroundOpen},
		isInfoPanelOpen: (state) => {return state.isInfoPanelOpen},
		isTooltipOpen: (state) => {return state.isTooltipOpen},
		defaultCamStats: (state) => {return state.defaultCamStats},
		infoPanelData: (state) => {return state.infoPanelData},
		isTutorialVisible: (state) => {return state.tutorialVisible},
		isFirstTimeTutorial: (state) => {return state.firstTimeTutorial},
	},
	mutations: {
		setToolTipsDisabled(state, boolean){
			state.toolTipsDisabled = boolean;
		},
		setLoadingscreenVisible(state, boolean){
			state.loadingscreenVisible = boolean;
		},
		setActiveLanguage(state, code){
			state.activeLanguage = code;
		},
		setProducts(state, array){
			state.products = array;
		},
		setLanguages(state,array){
			state.languages = array;
		},
		setAvailableSegments(state,object){
			state.availableSegments = object;
		},
		setWaterBool(state, boolean){
			state.waterEnabled = boolean;
		},
		setSewageBool(state, boolean){
			state.sewageEnabled = boolean;
		},
		setGasBool(state, boolean){
			state.gasEnabled = boolean;
		},
		setGroundBool(state, boolean){
			state.isGroundOpen = boolean;
		},
		toggleWaterBool(state){
			state.waterEnabled = !state.waterEnabled;
		},
		toggleSewageBool(state){
			state.sewageEnabled = !state.sewageEnabled;
		},
		toggleGasBool(state){
			state.gasEnabled = !state.gasEnabled;
		},
		toggleGroundBool(state){
			state.isGroundOpen = !state.isGroundOpen;
		},
		setDefaultCamTarget(state,target){
			state.defaultCamStats.target = target;
		},
		setDefaultCamStats(state,camStats){
			state.defaultCamStats = camStats;
		},
		setInfoPanelOpen(state,boolean){
			state.isInfoPanelOpen = boolean;
		},
		setTooltipOpen(state,boolean){
			state.isTooltipOpen = boolean;
		},
		setTooltipData(state,data){
			state.tooltipData = data;
		},
		setInfoPanelData(state, data){
			state.infoPanelData = data;
		},
		setTutorialVisible(state, boolean){
			state.tutorialVisible = boolean;
		},
		setFirstTimeTutorialBoolean(state, boolean){
			state.firstTimeTutorial = boolean;
		}
	}
})