<template>
	<div class="app">	
		<div class="debugButton" @click="toggleDebug()" v-if="isDevelopment">Debug</div>

		<div class="fullscreen-button" @click="toggleFullscreen()">
			<img v-show="isInFullscreen" :src="$getMedia('icons/fullscreenClose.png')" alt="">
			<img v-show="!isInFullscreen" :src="$getMedia('icons/fullscreenOpen.png')" alt="">
		</div>

		<div class="ui-level" :style="{left: isInfoPanelOpen ? '0px' : isMobile() ? '-100vw' : '-564px'}"> <!-- 564px = width of <ProductInfos/> -->
			<ProductInfos/>
			<NavigationTopLeft/>
		</div>
		
		<NavigationBottomRight/>
		<Tooltip/>
		<Tutorial/>

		<!-- Loadingscreen between Situations -->
		<transition name="fade">
			<Loadingscreen v-show="loadingscreenVisible"/>
		</transition>

		<!-- Rendering -->
		<Renderer>
			<Aliaxis v-if="dataLoaded"/>
		</Renderer>
	</div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'

// Components
import Loadingscreen from "@/components/ui/Loadingscreen.vue";
import NavigationTopLeft from "@/components/ui/NavigationTopLeft.vue";
import NavigationBottomRight from "@/components/ui/NavigationBottomRight.vue";
import ProductInfos from "@/components/ui/ProductInfos.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import Tutorial from "@/components/ui/Tutorial.vue";

// Rendering
import Renderer from "@/components/babylonjs/Renderer.vue";
import Aliaxis from "@/components/babylonjs/Aliaxis.vue";

// Json
import storeLinks from "@/assets/json/storeLinks.json";

export default {
	components: {Renderer, Aliaxis, Loadingscreen, NavigationTopLeft, NavigationBottomRight, ProductInfos, Tooltip, Tutorial},
	data: () => ({
		isInFullscreen: false,
		dataLoaded: false
	}),
	computed: {
		...mapGetters(['loadingscreenVisible',"isInfoPanelOpen"]),
		isDevelopment(){
			return import.meta.env.DEV;
		}
	},
	methods: {
		...mapMutations(['setLanguages','setProducts','setAvailableSegments',"setActiveLanguage"]),
		toggleFullscreen(){
			this.isInFullscreen = !this.isInFullscreen;

            let elem = document.getElementsByClassName("app")[0];

			if (this.isInFullscreen) {
                // Open
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
            }else{
				// Close
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }
            }
        },
		async toggleDebug(){
			await import('@babylonjs/inspector');
            await import("@babylonjs/core/Debug/debugLayer");
			if(window.scene.debugLayer.isVisible())
            	window.scene.debugLayer.hide();
			else
				window.scene.debugLayer.show();
		},
		isMobile(){
			return window.innerWidth < 900
		},
		async loadProductData() {
			console.log("-- Loading Product-Data --")

			let originalURL = new URL(window.location);
			let search_params = originalURL.searchParams;

			let url = storeLinks.regions.find(data => data.id == "DE").url;
			if(search_params.get("region")){
				url = storeLinks.regions.find(data => data.id == search_params.get("region")).url; // FIND language specific productJSON-link in storeLink-overview
				console.log("Region in params: " + search_params.get("region"));
			}
			if(this.isDevelopment){
				url = this.$getMedia("json/products_testing.json");
				console.log("Development mode: using products_testing.json");
			}
			

			// GET language specific products and language data and store them
			console.log("Fetching from url: " + url);
			await fetch(url).then(res => res.json()).then((out) => {
				this.$root.$i18n.locale = out.language.default;
				this.setActiveLanguage(out.language.default);
				this.setLanguages(out.language.available);
				this.setAvailableSegments(out.availableSegments);
				this.setProducts(out.products);

				console.log("-- Product-Data loaded --");
			})
		},
		async loadTranslationData(){
			console.log("-- Loading Translation-Data --")

			// Convert json to i18n readable structure
			const convertTranslation = (translationsJSON) => {
				translationsJSON.possibleLanguages.forEach(language => {
					const tmp = {};
					Object.entries(translationsJSON.translations).forEach(([key, values]) => {
						tmp[key] = values[language];
					});

					this.$root.$i18n.setLocaleMessage(language,tmp);
				});
			}

			var url = "https://www.aliaxis.de/website/aliaxis/showroom/system.json";
			if(this.isDevelopment){
				url = new URL(`/src/assets/json/translations_testing.json`, import.meta.url);
				console.log("Development mode: using translations_testing.json");
			}

			console.log("Fetching from url: " + url);
			fetch(url).then(res => res.json()).then((translationsJSON) => {
				convertTranslation(translationsJSON);
				console.log("-- Translation-Data loaded --");
			});
		},
		async loadData(){
			this.loadProductData();
			this.loadTranslationData();
			this.dataLoaded = true;
		}
	},
	created(){
		this.loadData();
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

:root {
  --main-orange: #32AAFF;
  --color-blue: #3066E3;
  --color-blue-hover: #1f55d1;
  --color-lightblue: #32AAFF;
  --color-red: #FA6157;
  --color-orange: #FAA64A;
  --color-turquoise: #52DEAD;
  --color-green: #57DE52;
  --color-purple: #D645CC;

  --combi-color-blue: var(--color-lightblue);
  --combi-color-red: var(--color-purple);
  --combi-color-orange: var(--color-red);
  --combi-color-turquoise: var(--color-green);

  --primaryColor: var(--color-lightblue);
  --md-theme-default-primary: var(--color-blue);
  
  --bg-dark: #5E5E5E;
  --bg-dark-hover: #6d6d6d; 
  --bg-light: rgb(250,250,250);
  --bg-light-hover: rgb(240,240,240);
}

html, body, #app, .app{
	margin: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	font-family: 'Questrial', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fullscreen-button{
	position: absolute;
	right: 20px;
	top: 20px;
	background-color: var(--color-blue);
	border-radius: 50%;
	width: 45px;
	height: 45px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.fullscreen-button img{
	width: 50%;
	height: 50%;
	filter: invert(1);
}
.ui-level {
	position: absolute;
	z-index: 2;
	left: 0px;
	top: 0px;
	display: flex;
	align-items: flex-start;
	transition: left .5s ease;
	pointer-events: none;
}
.debugButton{
	position: absolute;
	left: calc(50% - 60px);
	top: 20px;
	padding: 10px 40px;
	background: var(--color-blue);
	transition: all .3s ease;
	color: white;
	cursor: pointer;
	border-radius: 5px;
}
.debugButton:hover{
	background: var(--color-blue-hover);
}
/* General Design */
button{
	border: none;
	cursor: pointer;
	transition-duration: 300ms;
}
button.primary{
	font-size: 1rem;
	font-weight: 600;
	background-color: var(--color-lightblue);
	padding: 0.8125rem 1.5rem;
	color: white;
}
button.primary:hover{
	background-color: #007dd5;
}
button.tertiary{
	font-size: 1rem;
	font-weight: 600;
	background-color: transparent;
	padding: 0.8125rem 1.5rem;
	color: var(--bg-dark);
}
button.tertiary:hover{
	color: var(--color-lightblue);
}
button.fab{
	border-radius: 50%;
	display:flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: var(--bg-light);
	transition: .3s ease;
}
button.fab:hover{
	background-color: var(--bg-light-hover);
}
button.fab.s{
	height: 24px;
	width: 24px;
}
button.fab.m{
	height: 40px;
	width: 40px;
}
button.fab.l{
	height: 40px;
	width: 40px;
}
button.fab.dark{
	background-color: var(--bg-dark);
}
button.fab.dark:hover{
	background-color: var(--bg-dark-hover);
}
button.exit{
	position: absolute;
	top:0.5rem;
	right:0.5rem;
	z-index: 2;
}


/* loadingscreen fade animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>